<template>
    <div class="crypto">
        <h1 v-html="formattedId"></h1>

        <ul>
            <li
                v-for="(item, index) in cryptoContent"
                :key="index"
                class="crypto__section"
            >
                <h2>
                    {{
                        item.title
                    }}
                </h2>


                <ul>
                    <li v-for="(paragraph, newIndex) in item.text" :key="paragraph">
                        <!-- Only show in the beginning -->
                        <div
                            v-if="currentCryptoData && index === 0 && newIndex === 0"
                            class="crypto__info"
                        >
                            <img :src="`/img/cryptos/${id}.png`" alt="" class="crypto__logo">

                            <div class="crypto__details">
                                <span class="crypto__symbol">
                                    {{ currentCryptoData.symbol.toUpperCase() }}
                                </span>

                                <span class="crypto__rank">
                                    Rank: #{{ currentCryptoData.market_cap_rank }}
                                </span>

                                <span
                                    class="crypto__price-change"
                                    :class="{
                                        'crypto__price-change--up': usdPriceChangePercentage() === 'up',
                                        'crypto__price-change--down': usdPriceChangePercentage() === 'down',
                                    }"
                                >
                                    {{ currentCryptoData.market_data.price_change_percentage_24h.toFixed(2) }}%
                                </span>
                            </div>

                            <span class="crypto__current-price">
                                ${{ currentCryptoData.market_data.current_price.usd.toLocaleString() }}
                            </span>
                        </div>

                        <p v-html="paragraph">
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import axios from 'axios'

export default {
    name: 'Crypto',
    components: {
    },
    props: ['id', 'category'],
    data() {
        return {
            coingeckoApi: 'https://api.coingecko.com/api/v3',
            currentCryptoData: null,
        }
    },
    computed: {
        ...mapState(['device']),
        cryptoContent() {
            return this.$i18n.messages[this.id]
        },
        formattedId() {
            return this.id.charAt(0).toUpperCase() + this.id.slice(1).split('-')[0]
        },
    },
    methods: {
        getCryptoInfoCoingecko() {
            const currentCrypto = this.id.split('-')[0]

            axios
                .get(`${this.coingeckoApi}/coins/${currentCrypto}`)
                .then(response => {
                    this.currentCryptoData = response.data
                })
                .catch(error => {
                    console.log('error', error)
                })
        },
        usdPriceChangePercentage() {
            // If negative, then false
            if (Math.sign(this.currentCryptoData.market_data.price_change_percentage_24h) <= 0) {
                return 'down'
            }

            return 'up'
        }
    },
    mounted() {
        this.getCryptoInfoCoingecko()
    },
}

</script>

<style lang="scss">
.word-split {
    display: none;
}

.crypto {
    text-align: justify;

    h1 {
        margin-bottom: 24px;
        font-size: 26px;
    }

    h2 {
        font-size: 20px;
    }

    ul {
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    img {
        max-width: 160px;
        border-radius: 12px;

        @media (min-width: 1000px) {
            max-width: 220px;
        }
    }

    &__info {
        display: flex;
        width: 110px;
        margin: 6px 12px 0 0;
        padding: 12px;
        flex-wrap: wrap;
        float: left;
        border-radius: 12px;
        background-color: #143e5b;
        font-weight: 700;

        @media (min-width: 1000px) {
            width: 160px;
        }
    }

    &__logo {
        width: 45%;
        height: 100%;
        margin: auto 6px auto 0;
        object-fit: contain;
    }

    &__details {
        display: flex;
        margin: auto;
        flex-direction: column;
        text-align: center;
    }

    &__symbol {
        margin-bottom: 6px;
        font-size: 18px;

        @media (min-width: 1000px) {
            font-size: 22px;
        }
    }

    &__rank {
        margin-bottom: 6px;
        padding: 2px 4px;
        background-color: #3a6481;
        font-size: 8px;

        @media (min-width: 1000px) {
            font-size: 14px;
        }
    }

    &__price-change {
        font-size: 12px;

        @media (min-width: 1000px) {
            font-size: 14px;
        }

        &--up {
            color: #35cf08;
        }

        &--down {
            color: #e20f0f;
        }
    }

    &__current-price {
        margin: 6px auto 0;
        font-size: 18px;

        @media (min-width: 1000px) {
            margin-top: 12px;
            font-size: 22px;
        }
    }

    &__image {
        &--left {
            margin: 2px 12px 0 0;
            float: left;

            @media (min-width: 768px) {
                margin: 10px 12px 0 0;
            }
        }

        &--right {
            margin: 2px 0 0 12px;
            float: right;

            @media (min-width: 768px) {
                margin: 10px 0 0 12px;
            }
        }
    }

    &__section > ul {
        margin-bottom: 48px;

        @media (min-width: 768px) {
            column-count: 2;
            column-fill: balance;
            column-rule: 1px solid rgba(17, 226, 226, .5);
            column-gap: 24px;
        }
    }
}
</style>
